<template>
    <div>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['addBtn', 'perPage']"
                :addBtnText="$t(addButton)"
                :addBtnLink="$helper.getAddUrl('settings/' + baseUrl)"
                @perPageChange="perPageChange"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                @is-finished="isLoading = false"
            >
                <template v-slot:name="data">
                    {{ data.value.name }}
                </template>
                <template v-slot:description="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:notificationEmails="data">
                    <div class="vs__selected-options"
                         v-if="data.value.notificationEmails && data.value.notificationEmails.length">
            <span class="vs__selected" v-for="email in data.value.notificationEmails" :key="email">
              {{ email }}
            </span>
                    </div>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn :path="$helper.getEditUrl('settings/' + baseUrl, data.value.id)"/>
                        <delete-btn @pressDelete="deleteResource(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "Listing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn
    },
    props: {
        resource: {Object, required: true},
        baseUrl: {type: String, required: true},
        addButton: {type: String, required: true},
        deleteMessage: {type: String, required: true},
        emails: {type: Boolean, default: true}
    },
    mixins: [ResourceUtils],
    data() {
        return {
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            columns: [
                {
                    label: this.$t("forms.name"),
                    field: "name"
                },
                {
                    label: this.$t("forms.description"),
                    field: "description"
                }

            ],
        };
    },
    created() {
        if (this.emails) {
            this.columns.push(
                {
                    label: this.$t("forms.notificationEmails"),
                    field: "notificationEmails",
                    enabled: this.emails
                }
            )

        }
        this.columns.push(
            {
                label: this.$t("forms.actions"),
                field: "actions",
                width: "80px",
            }
        )
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            this.resource.getCollection({params}).then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteResource(url) {
            this.deleteByUrl(this.resource, url, this.$t(this.deleteMessage), null, this.load, this.error);
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
